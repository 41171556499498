<template>
  <div class="holder text-center mb-2 mb-md-0" @mouseover="isHover = true" @mouseleave="isHover = false">
    <div class="bold holder_font">{{placeholder}}</div>
    <div class="dropdown_icon">
      <slot />
    </div>
    <!--DESKTOP-->
    <transition name="slideBottom">
      <div class="select_dropdown" v-if="isHover" @mouseover="isHover = true" @click.self="isHover = false">
        <div class="dropdown_inner">
          <div class="row mx-0 my-4 justify-content-center" v-if="isAll">
            <div class="col-10 text-center title">
              <Button color="green" width="100" :btnText="placeholder + ' All (Summary)'" @buttonClicked="clickAll" />
            </div>
          </div>
          <div class="row mx-0 my-4 justify-content-center">
            <div class="col-10 text-center title">
              <Button color="green" width="100" :btnText="placeholder + ' Selected'" @buttonClicked="clickSelected" :disabled="disabled" />
            </div>
          </div>
          <div class="row mx-0 mt-2 justify-content-center scroll_inner">
            <div class="col-10 py-2">
              <div class="form-check medium">
                <div class="row m-0 align-items-center">
                  <div class="col-auto p-0">
                    <input class="form-check-input" type="checkbox" id="range" v-model="theSelected">
                  </div>
                  <div class="col-auto p-0">
                    <label class="form-check-label" for="range">
                      Choose Date Range
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-10" v-if="theSelected">
              <div class="my-3 text-start">
                <TextField type="text" color="outline" placeholder="First Date" label="First Date" v-model="selectedDate.startDate" id="start" :disabled="true">
                  <div class="position-absolute w-100 h-100 zindex" @click="showPicker1"></div>
                </TextField>
                <VueDatePicker v-model="selectedDate.startDate" inline auto-apply model-type="yyyy-MM-dd" :enable-time-picker="false" :month-change-on-scroll="false" v-if="isRangeStart"></VueDatePicker>
              </div>
              <div class="my-3 text-start">
                <TextField type="text" color="outline" placeholder="Last Date" label="End Date" v-model="selectedDate.endDate" id="end" :disabled="true">
                  <div class="position-absolute w-100 h-100 zindex" @click="showPicker2"></div>
                </TextField>
                <VueDatePicker v-model="selectedDate.endDate" inline auto-apply model-type="yyyy-MM-dd" :enable-time-picker="false" :month-change-on-scroll="false" v-if="isRangeEnd"></VueDatePicker>
              </div>
            </div>
          </div>
          <div class="row mx-0 my-3 justify-content-center" v-if="theSelected">
            <div class="col-10">
              <Button color="green" width="100" :btnText="placeholder + ' Date Range'" @buttonClicked="clickRange" :disabled="!dateRange.startDate || !dateRange.endDate" />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!--END DESKTOP-->
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '@/assets/css/datepicker.css'

export default {
  components: {
    // DatePicker,
    VueDatePicker,
    Button: defineAsyncComponent(() => import('./Button.vue')),
    TextField: defineAsyncComponent(() => import('./TextField.vue'))
  },
  name: 'ButtonDropDown',
  props: ['placeholder', 'disabled', 'isAll'],
  emits: ['update:modelValue', 'doSelected', 'doAll', 'doRange'],
  data () {
    return {
      date: null,
      isHover: false,
      theSelected: null,
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedDate: {
        startDate: 'First Date',
        endDate: 'Last Date'
      },
      isRangeStart: false,
      isRangeEnd: false
    }
  },
  watch: {
    selectedDate: {
      deep: true,
      handler (val) {
        this.setDateRange(this.selectedDate)
      }
    },
    theSelected () {
      this.setDateRange()
      this.isRangeEnd = false
      this.isRangeStart = false
    }
  },
  methods: {
    setDateRange () {
      if (this.theSelected) {
        if (this.selectedDate.startDate !== 'First Date') {
          this.dateRange.startDate = DateTime.fromISO(this.selectedDate.startDate).startOf('day').ts
        }
        if (this.selectedDate.endDate !== 'Last Date') {
          this.dateRange.endDate = DateTime.fromISO(this.selectedDate.endDate).endOf('day').ts
        }
      } else {
        this.dateRange.startDate = null
        this.dateRange.endDate = null
      }
    },
    showPicker1 () {
      this.isRangeStart = !this.isRangeStart
      this.isRangeEnd = false
    },
    showPicker2 () {
      this.isRangeEnd = !this.isRangeEnd
      this.isRangeStart = false
    },
    clickSelected () {
      this.$emit('doSelected')
    },
    clickAll () {
      this.$emit('doAll')
    },
    clickRange () {
      this.setDateRange()
      this.$emit('doRange', this.dateRange)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .holder {
    background-color: rgb(249, 250, 251);
    padding: 12px 24px;
    line-height: 1;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
  }
  .holder_font {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .dropdown_icon {
    position: absolute;
    top: 13px;
    right: 16px;
    font-size: 1rem;
    z-index: 1;
  }
  .select_dropdown {
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 60px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
    /* background-color: pink; */
  }
  .title {
    color: var(--green-color-dark);
    font-family: "quicksand_medium";
    font-weight: 600;
    font-size: 19px;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0.5rem;
    width: inherit;
  }
  .dropdown_inner {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 75vh;
    background-color: rgb(249, 250, 251);
    border-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: rgb(51, 51, 51);
  }
  .scroll_inner {
    max-height: 350px;
    overflow-y: auto;
  }
  .form-control {
    background-color: #fff;
    color: var(--green-color);
    border-color: var(--green-color);
    border-radius: 2rem;
    font-size: 1rem;
    height: 2.5rem;
    font-weight: 500;
    text-align: left;
  }
  .slideBottom-enter-active {
    transition: all 0.5s ease;
  }
  .slideBottom-leave-active {
    transition: all 0.5s ease;
  }
  .slideBottom-enter-from {
    transform: translateY(100%);
    opacity: 1;
  }
  .slideBottom-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  @media (min-width: 992px) {
    .select_dropdown {
      position: absolute;
      left: 0;
      bottom: inherit;
      top: 0;
      width: 100%;
      min-height: 60px;
      height: inherit;
      background-color: transparent;
      /* background-color: pink; */
    }
    .dropdown_inner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: inherit;
      max-height: inherit;
      top: 50px;
      width: 300%;
      min-width: 250px;
      background-color: rgb(249, 250, 251);
      border-radius: 20px;
      color: rgb(51, 51, 51);
    }

    .holder_font {
      font-size: 15px;
    }
    .holder {
      height: 40px;
      padding: 12px 48px 12px 32px;
      line-height: 1;
      border-radius: 100px;
      position: relative;
      cursor: pointer;
    }
    .slideBottom-enter-active {
      transition: all 0.5s ease;
    }
    .slideBottom-leave-active {
      transition: all 0.1s ease;
    }
    .slideBottom-enter-from {
      transform: translateY(0);
      opacity: 0;
    }
    .slideBottom-leave-to {
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
